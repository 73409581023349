.App {
  text-align: center;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  
  align-items: center;
  justify-content: center;
}

.no-decoration {
  text-decoration: none;
  color: black;
}

body {
  overflow-y: overlay;
}

.view {
  min-height: calc(100vh - 70px);
}

@media all and (max-width: 950px) {
  .view {
    min-height: calc(100vh - 70px);
  }
}

@media all and (min-width: 950px) {
  .view {
    min-height: calc(100vh - 100px);
  }
}